<script setup>
const props = defineProps({
    pageData: Object,
    links: Array,
    hideTotal: Boolean,
});
const emit = defineEmits(['setPage']);

const getPage = (url) => {
    const params = new URLSearchParams(url.split('?')[1]);

    return params.get('page');
};
</script>
<template>
    <div v-if="pageData.total !== 0" class="flex flex-wrap items-center justify-between">
        <span v-if="hideTotal"></span>
        <p class="text-base text-blue" v-else-if="pageData">
            {{ $t('Showing') }}
            <span class="font-bold">{{ pageData.from }}</span>
            {{ $t('to') }}
            <span class="font-bold">{{ pageData.to }}</span>
            {{ $t('of') }}
            <span class="font-bold">{{ pageData.total }}</span>
            {{ $t('results') }}
        </p>
        <div class="flex flex-wrap" v-if="links.length > 3">
            <template v-for="(link, key) in links">
                <div
                    v-if="link.url === null && key !== 0 && key !== links.length - 1"
                    :key="key"
                    class="px-4 py-3 text-base leading-4 border-t border-b border-l border-gray-border text-gray-dark w-[40px] h-[40px]"
                    v-html="link.label"
                />

                <button
                    v-else-if="key === 0"
                    :key="`first-${key}`"
                    class="flex items-center justify-center w-[40px] h-[40px] text-base leading-4 transition-colors border-t border-b border-l rounded-tl rounded-bl tb border-gray-border text-gray-dark hover:bg-blue/10 hover:border-blue/10"
                    :class="{
                        'text-gray-text': link.active,
                        'cursor-default': link.url === null,
                    }"
                    :href="link.url"
                    preserve-state
                    preserve-scroll
                    @click="emit('setPage', getPage(link.url) || 1)"
                >
                    <img
                        class="w-[10px] h-auto rotate-180"
                        src="@/../img/icon/pagination-arrow.svg"
                        :class="{
                            'opacity-30': link.url === null,
                        }"
                        :alt="$t('Previous page')"
                    />
                </button>

                <button
                    v-else-if="key === links.length - 1"
                    :key="`last-${key}`"
                    class="flex items-center w-[40px] h-[40px] justify-center border-gray-border border rounded-tr rounded-br px-1 mb-1 mr-1 text-base leading-4 transition-colors text-gray-dark hover:bg-blue/10 hover:border-blue/10"
                    :href="link.url"
                    preserve-state
                    preserve-scroll
                    :class="{
                        'cursor-default': link.url === null,
                    }"
                    @click="emit('setPage', getPage(link.url) || 1)"
                >
                    <img
                        class="w-[10px] h-auto"
                        src="@/../img/icon/pagination-arrow.svg"
                        :alt="$t('Next page')"
                        :class="{
                            'opacity-30': link.url === null,
                        }"
                    />
                </button>

                <button
                    v-else
                    :key="`link-${key}`"
                    class="flex items-center w-[40px] h-[40px] justify-center px-1 text-base font-bold leading-4 transition-colors border-t border-b border-l text-blue hover:bg-blue hover:border-blue duration-300 hover:text-white"
                    :class="{
                        'border-blue border': link.active,
                        'border-gray-border': !link.active,
                    }"
                    :href="link.url"
                    v-html="link.label"
                    preserve-state
                    preserve-scroll
                    @click="emit('setPage', getPage(link.url) || 1)"
                ></button>
            </template>
        </div>
    </div>
</template>
